import { createAction, props } from '@ngrx/store';

import {
  CreateWalletSerializersRequest,
  PaginatedWalletResponseSerializersList,
  WalletResponseSerializers,
} from '@offconon/core-api';

import { WalletEntity } from './wallet.models';

export const initWallet = createAction('[Wallet Page] Init');

export const loadWalletSuccess = createAction(
  '[Wallet/API] Load Wallet Success',
  props<{ wallet: WalletEntity[] }>(),
);

export const loadWalletFailure = createAction(
  '[Wallet/API] Load Wallet Failure',
  props<{ error: any }>(),
);

export const fetchWalletList = createAction(
  '[Wallet/API] Fetch Wallet List',
  props<{
    page?: number;
    pageSize?: number;
    searchJson?: {
      id?: number;
      business_id?: number;
      currency?: string;
    };
  }>(),
);

export const fetchWalletSuccess = createAction(
  '[Wallet/API] Set Wallet List',
  props<{ walletResponse: PaginatedWalletResponseSerializersList }>(),
);

export const setDefaultWallet = createAction(
  '[Wallet/API] Set default wallet',
  props<{ id: number }>(),
);
export const setDefaultWalletSuccess = createAction(
  '[Wallet/API] Set default wallet success',
  props<{ id: number }>(),
);

export const addWallet = createAction(
  '[Wallet/API] add wallet',
  props<{ createWalletSerializersRequest: CreateWalletSerializersRequest }>(),
);
export const addWalletSuccess = createAction(
  '[Wallet/API] add wallet success',
  props<{ wallet: WalletResponseSerializers }>(),
);

export const fetchWalletByCurrency = createAction(
  '[Wallet/API] fetch wallet by currency',
  props<{ currency: string; businessId?: number }>(),
);
