import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of, map, tap, filter, withLatestFrom } from 'rxjs';

import { WalletService } from '@offconon/core-api';

import * as WalletActions from './wallet.actions';
import { selectWalletResponse } from './wallet.selectors';

@Injectable()
export class WalletEffects {
  private actions$ = inject(Actions);
  private walletService = inject(WalletService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private store = inject(Store);

  loadWalletList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActions.fetchWalletList),
      switchMap((action) =>
        this.walletService
          .walletWalletsList(action.page || 1, action.pageSize || 10, action.searchJson)
          .pipe(
            map((walletResponse) =>
              WalletActions.fetchWalletSuccess({
                walletResponse,
              }),
            ),
            catchError((error) => of(WalletActions.loadWalletFailure({ error }))),
          ),
      ),
    ),
  );

  setDefault = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActions.setDefaultWallet),
      switchMap((action) =>
        this.walletService.walletSetDefaultWalletCreate({ id: action.id }).pipe(
          map(() =>
            WalletActions.setDefaultWalletSuccess({
              id: action.id,
            }),
          ),
          catchError((error) => of(WalletActions.loadWalletFailure({ error }))),
        ),
      ),
    ),
  );

  addWallet = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActions.addWallet),
      switchMap((action) =>
        this.walletService.walletCreateWalletCreate(action.createWalletSerializersRequest).pipe(
          tap(() => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('Successful'),
              detail: this.translateService.instant('Your data has been successfully saved.'),
            });
          }),
          map((wallet) => WalletActions.addWalletSuccess({ wallet })),
          catchError((error) => of(WalletActions.loadWalletFailure({ error }))),
        ),
      ),
    ),
  );

  loadWalletByCurrency$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActions.fetchWalletByCurrency),
      withLatestFrom(this.store.select(selectWalletResponse)),
      filter(
        ([action, walletResponse]) =>
          !walletResponse?.results?.find((wallet) => wallet.currency === action.currency),
      ),
      switchMap(([action]) =>
        this.walletService.walletWalletsList(1, 1, { currency: action.currency }).pipe(
          map((walletResponse) =>
            WalletActions.fetchWalletSuccess({
              walletResponse,
            }),
          ),
          catchError((error) => of(WalletActions.loadWalletFailure({ error }))),
        ),
      ),
    ),
  );
}
