import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { PaginatedWalletResponseSerializersList } from '@offconon/core-api';

import * as WalletActions from './wallet.actions';
import { WalletEntity } from './wallet.models';

export const WALLET_FEATURE_KEY = 'wallet';

export interface WalletState extends EntityState<WalletEntity> {
  selectedId?: number;
  loaded: boolean; // has the Wallet list been loaded
  error?: string | null; // last known error (if any)
  walletResponse?: PaginatedWalletResponseSerializersList;
}

export interface WalletPartialState {
  readonly [WALLET_FEATURE_KEY]: WalletState;
}

export const walletAdapter: EntityAdapter<WalletEntity> = createEntityAdapter<WalletEntity>();

export const initialWalletState: WalletState = walletAdapter.getInitialState({
  // set initial required properties

  loaded: false,
});

const reducer = createReducer(
  initialWalletState,
  on(WalletActions.initWallet, (state) => ({ ...state, loaded: false, error: null })),
  on(WalletActions.loadWalletSuccess, (state, { wallet }) =>
    walletAdapter.setAll(wallet, { ...state, loaded: true }),
  ),
  on(WalletActions.loadWalletFailure, (state, { error }) => ({ ...state, error })),
  on(WalletActions.fetchWalletSuccess, (state, { walletResponse }) => ({
    ...state,
    walletResponse,
  })),
  on(WalletActions.setDefaultWalletSuccess, (state, { id }) => ({
    ...state,
    walletResponse: {
      results: state.walletResponse?.results?.map((wallet) => {
        return {
          ...wallet,
          default: wallet.id === id,
        };
      }),
    },
  })),
  on(WalletActions.addWalletSuccess, (state, { wallet }) => ({
    ...state,
    walletResponse: {
      results: [...(state.walletResponse?.results ?? []), wallet],
    },
  })),
);

export function walletReducer(state: WalletState | undefined, action: Action) {
  return reducer(state, action);
}
