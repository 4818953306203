import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WALLET_FEATURE_KEY, WalletState, walletAdapter } from './wallet.reducer';

// Lookup the 'Wallet' feature state managed by NgRx
export const selectWalletState = createFeatureSelector<WalletState>(WALLET_FEATURE_KEY);

const { selectAll, selectEntities } = walletAdapter.getSelectors();

export const selectWalletLoaded = createSelector(
  selectWalletState,
  (state: WalletState) => state.loaded,
);

export const selectWalletError = createSelector(
  selectWalletState,
  (state: WalletState) => state.error,
);

export const selectAllWallet = createSelector(selectWalletState, (state: WalletState) =>
  selectAll(state),
);

export const selectWalletEntities = createSelector(selectWalletState, (state: WalletState) =>
  selectEntities(state),
);

export const selectSelectedId = createSelector(
  selectWalletState,
  (state: WalletState) => state.selectedId,
);

export const selectEntity = createSelector(
  selectWalletEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);

export const selectWalletResponse = createSelector(
  selectWalletState,
  (state: WalletState) => state.walletResponse,
);

export const selectSelectedWalletByCurrency = (currency: string) =>
  createSelector(selectWalletState, (state: WalletState) =>
    state.walletResponse?.results?.find((wallet) => wallet.currency === currency),
  );
